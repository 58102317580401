<template>
  <v-card>
    <TopKeystoneToolbar
      entity-type="siege"
      v-model="search"
      :open-new="openNewSeat"
      v-on:refresh="refresh"
    >
    </TopKeystoneToolbar>

    <SiegeForm
      :entity-id="currentSiegeId"
      :is-edit-form="isEditForm"
      v-model="siegeDialogForm"
      :default-entity="defaultEntity"
      v-on:refresh="refresh"
    >
    </SiegeForm>
    <HoldingForm
      v-model="holdingDialogForm"
      :is-edit-form="true"
      :entity-id="parentHoldingId"
      v-on:refresh="refresh"
      ref="holdingForm"
    />
    <CompanyForm
      :is-edit-form="true"
      :entity-id="parentCompanyId"
      v-model="companyDialogForm"
      :default-entity="defaultEntity"
      v-on:refresh="refresh"
      ref="companyForm"
    />
    <div class="entity-view-div">
      <v-data-table
        :headers-length="5"
        :loading="$store.getters.getLoadingSieges"
        :items="sieges"
        :headers="headers"
        :options.sync="pagination"
        :server-items-length="totalItems"
        @update:options="callApi"
        :footer-props="{
          itemsPerPageOptions: rowPerPageItem
        }"
      >
        <template v-slot:item="props">
          <tr>
            <td>
              {{ props.item.siege.id }}
            </td>
            <td>
              <strong>
                {{ props.item.siege.name }}
              </strong>
            </td>
            <td>
              <v-icon small>
                launch
              </v-icon>
              <a @click="openEditParentHolding(props.item.siege.company.holding.id)">{{ props.item.siege.company.holding.name }}</a>
            </td>
            <td>
              <v-icon small>
                launch
              </v-icon>
              <a @click="openEditParentCompany(props.item.siege.company.id)">{{ props.item.siege.company.name }}</a>
            </td>
            <td style="line-height: 1em;">
              <v-row wrap>
                <v-col cols="3">
                  <v-img
                    v-if="props.item && props.item.member && props.item.member.dsp"
                    :src="require('../../assets/' + props.item.member.dsp + '.png')"
                    aspect-ratio="1"
                    heigth="1em"
                    width="1em"
                    avatar
                    class="dsp-img"
                    :title="props.item.member.dsp"
                    :contain="true"
                  >
                  </v-img>
                </v-col>
                <v-col cols="auto" v-if="props.item.member && props.item.member.external_id">
                  <span style="font-style: italic">{{ props.item.member.external_id }}</span>
                </v-col>
                <v-col cols="3" v-if="props.item.member && props.item.member.id && props.item.member.external_id && !props.item.member.is_set">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        <v-icon style="font-size: 15px" color="red">
                          warning
                        </v-icon>
                      </div>
                    </template>
                    <span>
                    This member is not set !
                  </span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </td>
            <td>
              <v-btn icon>
                <v-icon @click="openEditSeat(props.item.id)">
                  edit
                </v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
  </v-card>
</template>

<script>
import store from '../../../store'
import { keystoneV2 } from '../../../store/modules/keystoneV2'
import TopKeystoneToolbar from '@/components/KeystoneV2/TopKeystoneToolbar'
import { rulesMixin } from '@/mixins/rulesMixin'
import SiegeForm from '@/components/KeystoneV2/Form/SiegeForm'
import HoldingForm from '@/components/KeystoneV2/Form/HoldingForm'
import CompanyForm from '@/components/KeystoneV2/Form/CompanyForm'
import SiegeSettingModel from '@/models/Keystone_v2/SiegeSettingModel'
import { openFormOptionMixin } from '@/mixins/openFormOptionMixin'
import { ROW_PER_PAGE_ITEM, TAB } from '@/components/KeystoneV2/commonConstantKeystone'
import { keystoneTabViewMixin } from '@/mixins/keystoneTabViewMixin'
import * as keystoneV2Utils from '../../../utils/keystoneV2Utils'
import { getEntityApiPayload } from '@/components/KeystoneV2/payloadHelperKeystone'
import _ from 'lodash'

// load the store module dynamically only when needed
if (!store.state.keystoneV2) store.registerModule('keystoneV2', keystoneV2)

export default {
  name: 'SiegeView.vue',
  components: {
    SiegeForm,
    HoldingForm,
    CompanyForm,
    TopKeystoneToolbar
  },
  mixins: [rulesMixin, openFormOptionMixin, keystoneTabViewMixin],
  data: function () {
    return {
      search: '',
      headers: [
        { text: 'Internal ID', value: 'siege.id', width: '15px', sortable: false },
        { text: 'Siege name', value: 'siege.name' },
        { text: 'Holding name', value: 'siege.company.holding.name' },
        { text: 'Company name', value: 'siege.company.name' },
        { text: 'Linked Member', value: 'member.external_id' },
        { text: 'Action', value: '', sortable: false }
      ],
      siegeDialogForm: false,
      holdingDialogForm: false,
      companyDialogForm: false,
      isEditForm: false,
      currentSiegeId: null,
      parentHoldingId: null,
      parentCompanyId: null,
      defaultEntity: null,
      entityType: 'siege',
      rowPerPageItem: ROW_PER_PAGE_ITEM,
      pagination: {}
    }
  },
  async mounted () {
    await this.callApiIfRightTab()
  },
  methods: {
    async callApi () {
      const payload = getEntityApiPayload(this.search, this.pagination, 'siegeId')
      await this.$store.dispatch('getApiKeystoneSieges', payload)
    },
    async refresh () {
      if (this.$store.getters.getLoadingSieges) {
        return
      }
      await this.callApi()
    },
    goToHolding (holding) {
      const option = {
        text: holding.name,
        id: holding.id
      }
      this.$store.commit('setTabSearchHoldings', option)
      const tab = keystoneV2Utils.getTabForEntity('holding')
      this.$store.commit('setCurrentTab', tab)
    },
    goToCompany (company) {
      const option = {
        text: company.name,
        id: company.id
      }
      this.$store.commit('setTabSearchCompanies', option)
      const tab = keystoneV2Utils.getTabForEntity('company')
      this.$store.commit('setCurrentTab', tab)
    },
    openNewSeat (company = null) {
      this.isEditForm = false
      this.defaultEntity = new SiegeSettingModel()
      // set default value
      this.defaultEntity.prodIsCuicui = true
      this.defaultEntity.prodIsAutoValidation = true

      if (company) {
        this.defaultEntity.siege.company = company
      }

      this.currentSiegeId = null
      this.siegeDialogForm = true
    },
    openEditSeat (siegeId) {
      this.isEditForm = true
      this.siegeDialogForm = true
      this.currentSiegeId = siegeId
    },
    openEditParentHolding (holdingId) {
      this.holdingDialogForm = true
      this.parentHoldingId = holdingId
    },
    openEditParentCompany (companyId) {
      this.companyDialogForm = true
      this.parentCompanyId = companyId
    },
    openNew (company = null) {
      this.openNewSeat(company)
    },
    openEdit (siegeId) {
      this.openEditSeat(siegeId)
    }
  },
  computed: {
    sieges: function () {
      return this.$store.getters.getSieges
    },
    totalItems: function () {
      return this.$store.getters.getTotalCountKeystoneSieges
    }
  },
  watch: {
    '$store.getters.getTabSearchSieges': {
      immediate: false,
      deep: true,
      handler: async function (newValue) {
        this.pagination.page = 1
        await this.callApi()
      }
    },
    search: _.debounce(function () {
      this.callApi()
    }, 600)
  }
}
</script>

<style scoped>

</style>
