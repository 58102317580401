export class DSPSettingsModel {
  constructor (data) {
    this.id = data.id
    this.clientName = data.client_name
    this.configStatus = data.config_status
    this.isActive = data.is_active
    this.projectId = data.project_id
    this.bucketNameShared = data.bucket_name_shared
    this.bucketNameSpillway = data.bucket_name_spillway
    this.dsp = data.dsp
  }
}

export class AppnexusDSPSettingsModel extends DSPSettingsModel {
  constructor (data) {
    super(data)
    this.isGdpr = data.is_gdpr
    this.isFullHashed = data.is_full_hashed
    this.dlRoutine = data.dl_routine
    this.bucketNameLld = data.bucket_name_lld
    this.downloadMethod = data.download_method
    this.pathAdserverConversions = data.path_adserver_conversions
    this.advertisersList = data.advertisers_list
    this.brokerId = data.broker_id
    this.partnerFeeId = data.partner_fee_id
  }
}

export class DbmDSPSettingsModel extends DSPSettingsModel {
  constructor (data) {
    super(data)
    this.dtActivated = data.dt_activated
    this.dtLevel = data.dt_level
    this.reportsOnly = data.reports_only
    this.dtConfiguration = {
      status: data.dt_configuration.status,
      bucketType: data.dt_configuration.bucket_type,
      bucketLevel: data.dt_configuration.bucket_level
    }
    this.vCPMcPCVcompatibility = data.vCPM_CPCV_compatibility
    this.bucketList = data.bucket_list
    this.datasetImport = data.dataset_import
    this.reportLld = data.report_lld
    this.reportLldConv = data.report_lld_conv
    this.sdfVersion = data.sdf_version
    this.dlRoutine = data.dl_routine
    this.advertisersList = data.advertisers_list
    this.bucketPrefix = data.bucket_prefix
    this.bucketType = data.bucket_type
    this.customAlgoGroups = data.custom_algo_groups
  }
}

export class MediaMathDSPSettingsModel extends DSPSettingsModel {
  constructor (data) {
    super(data)
    this.lldConfiguration = {
      status: data.lld_configuration.status,
      bucketLevel: data.lld_configuration.bucket_level
    }
    this.advertisersListDownload = data.advertisers_list_download
    this.dlRoutine = data.dl_routine
  }
}

export class BeeswaxDSPSettingsModel extends DSPSettingsModel {
  constructor (data) {
    super(data)
    this.lldConfiguration = {
      status: data.lld_configuration.status,
      bucketLevel: data.lld_configuration.bucket_level
    }
    this.bucketS3 = data.bucket_s3
    this.bucketS3Prefix = data.bucket_s3_prefix
    this.dlRoutine = data.dl_routine
    this.masquerade = data.masquerade
    this.advertisersList = data.advertisers_list
  }
}

export class TheTradeDeskDSPSettingsModel extends DSPSettingsModel {
  constructor (data) {
    super(data)
    this.lldConfiguration = {
      status: data.lld_configuration.status,
      bucketLevel: data.lld_configuration.bucket_level
    }
    this.reportTemplateId = data.report_template_id
    this.dlRoutine = data.dl_routine
    this.streaming = data.streaming
    this.reportAdvertiserFilter = data.report_advertiser_filter
    this.advertisersList = data.advertisers_list
  }
}

export class FacebookDSPSettingsModel extends DSPSettingsModel {
  constructor (data) {
    super(data)
    this.dataLevel = data.data_level
    this.businessId = data.business_id
    this.advertisersList = data.advertisers_list
  }
}

export class MetaDspSettingsModel extends DSPSettingsModel {}

export class OtherDSPSettingsModel extends DSPSettingsModel {
  constructor (data) {
    super(data)
    this.dataLevel = data.data_level
  }
}

export class DSPSettingsModelFactory {
  /**
   * @param dsp
   * @param data
   * @return {DSPSettingsModel}
   */
  getDspSettingsModel (dsp, data) {
    if (dsp === 'appnexus') {
      return new AppnexusDSPSettingsModel(data)
    } else if (dsp === 'dbm') {
      return new DbmDSPSettingsModel(data)
    } else if (dsp === 'mediamath') {
      return new MediaMathDSPSettingsModel(data)
    } else if (dsp === 'beeswax') {
      return new BeeswaxDSPSettingsModel(data)
    } else if (dsp === 'thetradedesk') {
      return new TheTradeDeskDSPSettingsModel(data)
    } else if (dsp === 'facebook') {
      return new FacebookDSPSettingsModel(data)
    } else if (dsp === 'meta') {
      return new MetaDspSettingsModel(data)
    } else {
      return new OtherDSPSettingsModel(data)
    }
  }
}
